window.addEventListener( 'load', footerMargin, { passive: true } );
window.addEventListener( 'resize', footerMargin, { passive: true } );

function footerMargin() {
    let footer = jQuery( '.site-footer' );
    let stickyFooter = footer.find( '.sticky' );

    if (stickyFooter.css('display') != "none") {
        setTimeout( function() {
            var footerHeight = stickyFooter.outerHeight();
            footer.css( 'padding-bottom', footerHeight + 'px' );
        }, 400 );
    } else {
        footer.css('padding-bottom', 0);
    }
}
