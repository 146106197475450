export const prevArrow = `<span class="slick-arrow slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="38.621" height="23.941" viewBox="0 0 38.621 23.941"><g id="Group_11" data-name="Group 11" transform="translate(2 2)"><path id="Path_25" data-name="Path 25" d="M7440.332,189.376H7406.54a2,2,0,0,1,0-4h33.792a2,2,0,0,1,0,4Z" transform="translate(-7406.54 -177.539)" fill="#043853"/><path id="Path_26" data-name="Path 26" d="M7427.915,202.394a2,2,0,0,1-1.414-3.414l8.557-8.556-8.557-8.556a2,2,0,1,1,2.828-2.829l11.386,11.385-11.386,11.384A1.994,1.994,0,0,1,7427.915,202.394Z" transform="translate(-7404.094 -180.454)" fill="#043853"/></g></svg></span>`;
export const nextArrow = `<span class="slick-arrow slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="38.621" height="23.941" viewBox="0 0 38.621 23.941"><g id="Group_11" data-name="Group 11" transform="translate(2 2)"><path id="Path_25" data-name="Path 25" d="M7440.332,189.376H7406.54a2,2,0,0,1,0-4h33.792a2,2,0,0,1,0,4Z" transform="translate(-7406.54 -177.539)" fill="#043853"/><path id="Path_26" data-name="Path 26" d="M7427.915,202.394a2,2,0,0,1-1.414-3.414l8.557-8.556-8.557-8.556a2,2,0,1,1,2.828-2.829l11.386,11.385-11.386,11.384A1.994,1.994,0,0,1,7427.915,202.394Z" transform="translate(-7404.094 -180.454)" fill="#043853"/></g></svg></span>`;

export const defaultOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false,
    cssEase: 'ease',
    speed: 600,
    prevArrow: prevArrow,
    nextArrow: nextArrow
};

export const prevArrowCustom = (slider) => {
    return slider.next('.arrows').find('.slick-prev');
};

export const nextArrowCustom = (slider) => {
    return slider.next('.arrows').find('.slick-next');
};
