import { defaultOptions, prevArrowCustom, nextArrowCustom } from "../utilities/slider";

const accreditationsSlider = document.querySelectorAll('[data-slider="accreditations-slider"');
if (accreditationsSlider.length) {
    accreditationsSlider.forEach(slider => {
        if (slider.dataset.sliderLayout == 'rows') {
            jQuery(slider).slick({
                slidesToShow: 6,
                slidesToScroll: 1,
                fade: false,
                dots: false,
                rows: 0,
                centerMode: true,
                centerPadding: '0',
                focusOnSelect: false,
                autoplay: true,
                arrows: true,
                prevArrow: jQuery('.accreditations-rows .arrows .prev'),
                nextArrow: jQuery('.accreditations-rows .arrows .next'),
                responsive: [
                    {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                    },
                    {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                    }
                ]
            });
        } else if (slider.dataset.sliderLayout == 'columns') {
            jQuery(slider).slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 3,
                rows: 3,
                slidesToScroll: 1,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 4000,
                pauseOnHover: true,
                pauseOnFocus: true,
                responsive: [
                    {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                    },
                    {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        rows: 1,
                        centerMode: true,
                    }
                    },
                ]
            });
        }
    });
}
