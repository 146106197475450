import { toggleBooleanAttribute } from "../utilities/attributes";

//@ts-check
document.querySelectorAll( '.accordion-block' ).forEach( accordionBlock => {
    const accordionSections = accordionBlock.querySelectorAll( '.accordion-section' );
    accordionSections.forEach( accordionSection => {
        accordionSection.addEventListener( 'click', toggleAccordion );
    } );

    function toggleAccordion( e ) {
        accordionSections.forEach( accordionSection => {
            const content = accordionSection.querySelector( '.accordion-content' );
            if ( accordionSection !== e.currentTarget ) {
                accordionSection.classList.remove( 'open' );
                content.setAttribute( 'aria-hidden', 'true' );
            } else {
                accordionSection.classList.toggle( 'open' );
                toggleBooleanAttribute( 'aria-hidden', content );
            }
        } );
    }
} );

