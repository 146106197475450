import { defaultOptions } from "../utilities/slider";

jQuery(".staggered-section .staggered-slider").slick({
    ...defaultOptions,
    autoplaySpeed: 15000,
    slidesToShow: 1,
    infinite: true,
    arrows: false,
    dots: true,
});

jQuery(".staggered-section .images").slick({
    ...defaultOptions,
    slidesToShow: 1,
    infinite: true,
    arrows: false,
});

jQuery(".staggered-section .play-video").on("click", function () {
    var video = jQuery(this).siblings("video");
    jQuery(video).get(0).play();

    jQuery(video).attr("controls", true);

    jQuery(this).fadeOut();
});
