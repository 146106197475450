import { defaultOptions } from "../utilities/slider";

const testimonialSliders = document.querySelectorAll(
    '[data-slider="testimonials-slider"'
);
if (testimonialSliders.length) {
    testimonialSliders.forEach((slider) => {
        let parentEle = jQuery(slider.closest(".testimonials"));
        if (slider.dataset.sliderLayout == "columns") {
            jQuery(slider).slick({
                ...defaultOptions,
                infinite: true,
                centerMode: true,
                centerPadding: "100px",
                arrows: false,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 1,
                            centerPadding: "200px",
                        },
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            centerPadding: "100px",
                        },
                    },
                    {
                        breakpoint: 501,
                        settings: {
                            slidesToShow: 1,
                            centerPadding: "30px",
                        },
                    },
                ],
            });
        } else {
            jQuery(slider).slick({
                ...defaultOptions,
                slidesToShow: 1,
                infinite: true,
                arrows: false,
            });
        }
    });
}
