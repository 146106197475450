import { prevArrow, nextArrow } from "../utilities/slider";

const options = {
    slidesToShow: 3,
    swipeToSlide: true,
    infinite: false,
    cssEase: 'ease',
    speed: 600
};

jQuery('[data-slider="blog-slider"]').slick({
	...options,
	slidesToShow: 2 + 1 / 3,
	nextArrow,
	prevArrow,
	responsive: [
		{
			breakpoint: 1300,
			settings: {
                slidesToShow: 4 / 3,
			}
		},
		{
			breakpoint: 768,
			settings: {
                slidesToShow: 1,
			}
		},
	],
});
