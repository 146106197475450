import { defaultOptions, prevArrowCustom, nextArrowCustom } from "../utilities/slider";

const iconsSlider = jQuery('[data-slider="icons-slider"]');

if (iconsSlider.length) {
	let numberColumns = iconsSlider.data('slider-columns');
	iconsSlider.slick({
        ...defaultOptions,
		slidesToShow: numberColumns,
		arrows: true,
		rows: 0,
		prevArrow: prevArrowCustom(iconsSlider),
		nextArrow: nextArrowCustom(iconsSlider),
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 639,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});
}
