// @ts-check

/**
 * @param {string} selector
 * @param  {Document | HTMLElement} context
 *
 * @return {HTMLElement | null}
 */
export function get( selector, context = document ) {
    return context.querySelector( selector );
}

/**
 * @param {string} selector
 * @param  {Document | HTMLElement} context
 *
 * @return {NodeListOf<HTMLElement>}
 */
export function getAll( selector, context = document ) {
    return context.querySelectorAll( selector );
}

/**
 * @param {string} id
 *
 * @return {HTMLElement | null}
 */
export function getById( id ) {
    return document.getElementById( id );
}

/**
 * @param {string} tagName
 *
 * @return {HTMLCollectionOf<HTMLElement>}
 */
export function getByTag( tagName ) {
    // @ts-ignore We're just going to assume they're HTMLElements
    return document.getElementsByTagName( tagName );
}