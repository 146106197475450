// @ts-check

import { get, getAll } from "../utilities/querySelector";

class TabsPanes {
    constructor( section ) {
        this.wrapper = section;
        this.tabsWrap = get( '[data-window-tabs]', section );
        this.panesWrap = get( '[data-window-panes]', section );
        this.tabs = getAll( '[data-tab]', section );
        this.panes = getAll( '[data-pane]', section );
        this.breakpoint = window.matchMedia( '(min-width: 1024px)' );

        this.tabs.forEach( tab => {
            tab.addEventListener( 'click', this.onTabClick.bind( this ) );
        } );

        this.assignMode();
        this.breakpoint.addEventListener( 'change', this.assignMode.bind( this ) );
        section.classList.add( 'window--initialized' );
    }

    /**
     * @param {MouseEvent} e
     *
     * @return void
     */
    onTabClick( e ) {
        const tab = e.currentTarget;

        if ( !( tab instanceof HTMLElement ) ) return;

        this.tabs.forEach( tab => tab.classList.remove( 'active' ) );
        tab.classList.add( 'active' );

        const pane = this.getTabsPane( tab );

        if ( !( pane instanceof HTMLElement ) ) return;

        const activePane = get( '[data-pane].active', this.wrapper );

        if ( activePane === pane ) return;

        e.preventDefault();

        const anim = deactivatePane( activePane );
        anim.addEventListener( 'finish', () => {
            activatePane( pane );
        } );
    }

    assignMode() {
        if ( this.breakpoint.matches ) {
            this.setDesktopMode();
        } else {
            this.setMobileMode();
        }
    }


    setMobileMode() {
        this.tabs.forEach( tab => {
            const pane = this.getTabsPane( tab );
            tab.after( pane );
        } );
    }


    setDesktopMode() {
        this.tabs.forEach( tab => {
            const pane = this.getTabsPane( tab );
            tab.after( pane );
            this.panesWrap.appendChild( pane );
        } );
    }

    /**
     *
     * @param {HTMLElement} tab
     *
     * @returns {HTMLElement|undefined}
     */
    getTabsPane( tab ) {
        const id = tab.dataset.tab;
        return get( `[data-pane="${id}"]`, this.wrapper );
    }
}

const windows = getAll( '[data-window]' );

windows.forEach( window => {
    new TabsPanes( window );
} );


/**
 * @param {HTMLElement} pane
 *
 * @return {Animation}
 */
function deactivatePane( pane ) {
    const animation = pane.animate(
        {
            opacity: [1, 0],
            translate: ['0 0', '1rem 0']
        }, { duration: 200, easing: 'ease' }
    );
    animation.addEventListener( 'finish', () => {
        pane.classList.remove( 'active' );
    } );
    return animation;
}

/**
 * @param {HTMLElement} pane
 *
 * @return {Animation}
 */
function activatePane( pane ) {
    pane.classList.add( 'active' );
    const animation = pane.animate( {
        opacity: [0, 1],
        translate: ['1rem 0', '0 0']
    }, { duration: 200, easing: 'ease' } );
    return animation;
}
