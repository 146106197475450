
/**
 * @param {string} attr Attribute of the element to toggle
 * @param {HTMLElement} el
 *
 * @return void
 */
export function toggleBooleanAttribute( attr, el ) {
    el.setAttribute( attr, is_attr( attr, el ) ? "false" : "true" );
}

/**
 * @param {string} attr Attribute of the element to check if true
 * @param {HTMLElement} el
 *
 * @return void
 */
export function is_attr( attr, el ) {
    return el.getAttribute( attr ) === "true" ? true : false;
}