import "slick-carousel";
import "slick-lightbox";

import AOS from "aos";
AOS.init({
    disable: "mobile",
    once: true,
    duration: 500,
    anchorPlacement: "top-bottom",
});

// global
import "./global/global.js";
import "./global/modal.js";
import "./global/header.js";
import "./global/footer.js";

import "./template-parts/accordion.js";
import "./template-parts/single-post.js";
import "./template-parts/card-slider.js";
import "./template-parts/range-slider.js";
import "./template-parts/form.js";
import "./template-parts/windows.js";
import "./template-parts/property-map.js";

// blocks
import "./blocks/accordion.js";
import "./blocks/latest-blogs-slider.js";
import "./blocks/accreditations-slider.js";
import "./blocks/blog-listing.js";
import "./blocks/staggered-content.js";
import "./blocks/icons.js";
import "./blocks/testimonial-slider.js";
import "./blocks/featured-properties-slider.js";
import "./blocks/tabs.js";
import "./blocks/maps.js";
