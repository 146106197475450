// @ts-check

jQuery( '.burgerBox' ).on( 'click', function() {
    jQuery( '.burgerBun' ).toggleClass( "open" );
    jQuery( '.menu-mobile-menu-container' ).toggleClass( "open" );

    var headerHeight = jQuery( '#site-header' ).outerHeight()
    let stickyFooter = jQuery( '.site-footer .sticky' ).outerHeight();
    jQuery( '.menu-mobile-menu-container' ).css( "max-height", "calc(100vh - " + headerHeight + "px - " + stickyFooter + "px)");
} );

jQuery( '.menu-mobile-menu-container a' ).on( 'click', function() {
    jQuery( '.burgerBun' ).removeClass( "open" );
    jQuery( '.menu-mobile-menu-container' ).removeClass( "open" );
} );


let lastScroll = 0;
const header = /** @type {HTMLElement}*/ ( document.getElementById( 'site-header' ) );
setHeaderScrollState();
window.addEventListener( 'scroll', setHeaderScrollState, { passive: true } );
window.addEventListener( 'resize', setHeaderScrollState, { passive: true } );

function setHeaderScrollState() {
    if ( window.scrollY > 67) {
        if (lastScroll > window.scrollY) {
            header.classList.remove( 'scroll' );
        } else {
            header.classList.add( 'scroll' );
        }
    } else {
        header.classList.remove( 'scroll' );
    }
    lastScroll = window.scrollY;
}

// window.addEventListener( 'load', headerMargin, { passive: true } );
// window.addEventListener( 'resize', headerMargin, { passive: true } );

function headerMargin() {
  let position = jQuery( '#site-header' ).css( 'position' );
  if (position == 'fixed') {
    setTimeout( function() {
        if (header.classList.contains( 'scroll' )) { return }
        var headerHeight = jQuery( '#site-header' ).outerHeight(); // + headerPadding;
        jQuery( 'body' ).css( 'margin-top', headerHeight + 'px' );
    }, 400 );
  }
}
