import { toggleBooleanAttribute } from "../utilities/attributes";

const forms = document.querySelectorAll("[data-form-submit-on-change]");

if (forms) {
    forms.forEach((form) => {
        const inputs = form.querySelectorAll("input, select");
        inputs.forEach((input) =>
            input.addEventListener("change", () => {
                if (form.dataset.form === "filter-news") {
                    window.location.href = input.value;
                    return;
                }

                form.submit();
            })
        );
    });
}

const filterForm = document.querySelector('[data-form="filter"]');
if (filterForm) {
    let formSections = filterForm.querySelectorAll("[data-form-filters]");

    filterForm.addEventListener("input", () => {
        let submitButtons = filterForm.querySelectorAll("[data-form-submit]");
        submitButtons.forEach((submitButton) => {
            submitButton.classList.add("active");
        });
    });

    formSections.forEach((formSection) => {
        formSection
            .querySelector("[data-form-filter-heading]")
            .addEventListener("click", (e) => {
                toggleFilterOptions(e, formSection);
            });
    });

    function toggleFilterOptions(e, formSection) {
        e.preventDefault();
        let filterHeading = formSection.querySelector(
            "[data-form-filter-heading]"
        );
        let filterOptions = formSection.querySelector(
            "[data-form-filter-options]"
        );
        if (filterHeading !== e.currentTarget) {
            filterHeading.classList.remove("open");
            filterOptions.classList.remove("open");
            filterOptions.setAttribute("aria-hidden", "true");
        } else {
            filterHeading.classList.toggle("open");
            filterOptions.classList.toggle("open");
            toggleBooleanAttribute("aria-hidden", filterOptions);
        }
    }
}
