import { defaultOptions, prevArrow, nextArrow } from "../utilities/slider";

const cardSliders = document.querySelectorAll('[data-slider="card-slider"]');

if (cardSliders.length) {
    cardSliders.forEach((slider) => {
        const cardSliderSize = slider.dataset.sliderSize;

        jQuery(slider).slick({
            ...defaultOptions,
            nextArrow,
            prevArrow,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
    });
}
