jQuery( 'img.svg' ).each( function() {
  const $img = jQuery( this );
  const imgID = $img.attr( 'id' );
  const imgClass = $img.attr( 'class' );
  const imgURL = $img.attr( 'src' );

  jQuery.get( imgURL, function( data ) {
    let $svg = jQuery( data ).find( 'svg' );
    if ( typeof imgID !== 'undefined' ) {
      $svg = $svg.attr( 'id', imgID );
    }
    if ( typeof imgClass !== 'undefined' ) {
      $svg = $svg.attr( 'class', imgClass + ' replaced-svg' );
    }
    $svg = $svg.removeAttr( 'xmlns:a' );
    if ( !$svg.attr( 'viewBox' ) && $svg.attr( 'height' ) && $svg.attr( 'width' ) ) {
      $svg.attr( 'viewBox', '0 0 ' + $svg.attr( 'height' ) + ' ' + $svg.attr( 'width' ) );
    }
    $img.replaceWith( $svg );
  }, 'xml' );
} );

jQuery( '.back-to-top' ).on( 'click', function() {
  jQuery( 'html, body' ).animate( { scrollTop: 0 }, 'fast' );
} );