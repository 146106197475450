import AOS from 'aos';

jQuery( "#more_posts" ).on( "click touch keydown", function( e ) {
	if (
		e.originalEvent instanceof KeyboardEvent
		&& ( e.key !== "Enter" && e.key !== " " )
	) return;

	e.preventDefault();
	let offset = jQuery( '#offset' ).val();
	jQuery( '#more_posts' ).html( 'Loading...' );
	jQuery( '#more_posts' ).addClass( 'loading' );

	jQuery.ajax( {
		type: 'POST',
		url: '/wp-admin/admin-ajax.php',
		dataType: 'html',
		data: {
			action: "more_post_ajax",
			offset: offset,
		},
		success: function( res ) {
			jQuery( '#ajax-container' ).append( res );
			offset++;
			jQuery( '#offset' ).val( offset );
			jQuery( '#more_posts' ).html( 'Load more' );
			jQuery( '#more_posts' ).removeClass( 'loading' );
			if ( res == '<div class="small-12 columns" style="text-align:center;"><h4 class="no-posts">No posts to display.</h4></div><style>.load-button-container { display:none; }</style>' ) {
				jQuery( '.no-posts' ).hide();
				jQuery( '#more_posts' ).hide();
				jQuery( '.no-more' ).show();
				setTimeout( function() {
					jQuery( '.no-more' ).fadeOut();
				}, 3500 );
			}

            // Reinitialize AOS
            AOS.init({
                disable: 'mobile',
                once: true,
                duration: 500,
                anchorPlacement: 'top-bottom',
            });
		},
		error: function( res ) {
		}
	} );
} );

// Reset next posts to fetch and scroll top of archive into view
// if archive page is accessed via forward / back buttons
window.addEventListener( "pageshow", () => {
	performance.getEntriesByType( "navigation" ).forEach( navigationEntry => {
		if ( navigationEntry.type === 'back_forward' ) {
			jQuery( '#offset' ).val( 1 );
			// Doesn't seem to work consistently without this delay
			requestAnimationFrame( () => jQuery( '.select-col' ).get( 0 )?.scrollIntoView() );
		}
	} );
} );
