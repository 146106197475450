import { toggleBooleanAttribute } from "../utilities/attributes";

const prevArrow = `<span class="slick-arrow slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="16.314" height="10.54" viewBox="0 0 16.314 10.54"><path id="Path_146" data-name="Path 146" d="M1.462,14.949l-2.924-2.73L3.606,6.792-1.462,1.365l2.924-2.73L9.078,6.792Z" transform="translate(14.949 1.462) rotate(90)" fill="#fba618"/></svg></span>`;
const nextArrow = `<span class="slick-arrow slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="16.314" height="10.54" viewBox="0 0 16.314 10.54"><path id="Path_146" data-name="Path 146" d="M1.462,14.949l-2.924-2.73L3.606,6.792-1.462,1.365l2.924-2.73L9.078,6.792Z" transform="translate(14.949 1.462) rotate(90)" fill="#fba618"/></svg></span>`;
const galleryMain = jQuery('[data-slider="single-gallery"]');
const galleryThumbs = jQuery('[data-slider="single-gallery-nav"]');

if (galleryMain.length) {
    galleryMain.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        autoplay: false,
        rows: 0,
        asNavFor: galleryThumbs,
    });
}

if (galleryThumbs.length) {
    galleryThumbs.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: galleryMain,
        autoplay: false,
        dots: false,
        fade: false,
        arrows: true,
        centerMode: true,
        rows: 0,
        centerPadding: "0",
        prevArrow: prevArrow,
        nextArrow: nextArrow,
    });
}

jQuery("#moreBlogPostsSlider").slick({
    slidesToShow: 2.2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: false,
    centerMode: false,
    arrows: true,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
                centerMode: true,
            },
        },
    ],
});

document
    .querySelectorAll("[data-dropdown-trigger]")
    .forEach((dropdownTrigger) => {
        dropdownTrigger.addEventListener("click", toggleDropdown);

        function toggleDropdown(e) {
            e.preventDefault();
            const dropdown = dropdownTrigger.nextElementSibling;
            dropdownTrigger.classList.toggle("open");
            dropdown.classList.toggle("open");
            toggleBooleanAttribute("aria-hidden", dropdown);
        }
    });
