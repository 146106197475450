import { toggleBooleanAttribute } from "../utilities/attributes";

//@ts-check
const tabBlocks = document.querySelectorAll('[data-tabs]');
if (tabBlocks.length) {
    tabBlocks.forEach( tabBlock => {
        const tabLinks = tabBlock.querySelectorAll('.tab-links .tab-link');
        const tabSections = tabBlock.querySelectorAll('.tab-content .tab-pane');
    
        tabLinks.forEach( tabLink => {
            tabLink.addEventListener( 'click', toggleTab );
        } );
    
        function toggleTab( e ) {
            e.preventDefault();
    
            const tabId = this.querySelector('a').getAttribute('href');
            tabLinks.forEach( tabLink => {
                if ( tabLink.querySelector('a').getAttribute('href') !== tabId ) {
                    tabLink.classList.remove( 'active' );
                } else {
                    tabLink.classList.toggle( 'active' );
                }
            });
    
            tabSections.forEach( tabSection => {
                const tabTarget = tabId.replace('#', '');
                if ( tabSection.getAttribute('id') !== tabTarget ) {
                    tabSection.classList.remove( 'active' );
                    tabSection.setAttribute( 'aria-hidden', 'true' );
                } else {
                    tabSection.classList.toggle( 'active' );
                    toggleBooleanAttribute( 'aria-hidden', tabSection );
                }
            } );
        }
    } );
}

const tabAccordionBlocks = document.querySelectorAll('[data-tabs-accordion]');
if (tabAccordionBlocks.length) {
    tabAccordionBlocks.forEach( tabAccordionBlock => {
        const tabAccordionSections = tabAccordionBlock.querySelectorAll( '.tab-accordion-section' );
        tabAccordionSections.forEach( tabAccordionSection => {
            tabAccordionSection.addEventListener( 'click', toggleAccordion );
        } );

        function toggleAccordion( e ) {
            tabAccordionSections.forEach( tabAccordionSection => {
                const content = tabAccordionSection.querySelector( '.tab-accordion-content' );
                if ( tabAccordionSection !== e.currentTarget ) {
                    tabAccordionSection.classList.remove( 'open' );
                    content.setAttribute( 'aria-hidden', 'true' );
                } else {
                    tabAccordionSection.classList.toggle( 'open' );
                    toggleBooleanAttribute( 'aria-hidden', content );
                }
            } );
        }
    });
}
