import { defaultOptions, prevArrow, nextArrow } from "../utilities/slider";

jQuery('[data-slider="featured-properties-slider"]').slick({
    ...defaultOptions,
    slidesToShow: 2 + 2/3,
    autoplay: false,
    nextArrow,
    prevArrow,
    responsive: [
        {
            breakpoint: 1550,
            settings: {
                slidesToShow: 2.5,
            }
        },
        {
            breakpoint: 1350,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 1050,
            settings: {
                slidesToShow: 1.5,
            }
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
            }
        },
    ],
});
