const mapDisplay = document.querySelector("#property_map");

if (mapDisplay) {
    var mapJson = mapDisplay.dataset.mapJson;
    var mapData = JSON.parse(mapJson);
    var propertyMap = mapData.propertyMap;
    var mapUea = mapData.mapUea;
    var mapNua = mapData.mapNua;

    var map;
    var marker;
    var infowindow;

    jQuery(document).ready(function () {
        if (typeof google == "object") {
            initialize_this_map();
        } else {
            jQuery("#property_map").hide();
        }
    });

    function initialize_this_map() {
        var myLatlng = new google.maps.LatLng(propertyMap.lat, propertyMap.lng);
        var myOptions = {
            zoom: propertyMap.zoomLevel,
            center: myLatlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
        };

        map = new google.maps.Map(
            document.getElementById("property_map"),
            myOptions
        );

        marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            title: propertyMap.title,
            icon: propertyMap.icon,
        });

        uea = new google.maps.Marker({
            position: { lat: 52.6219215, lng: 1.2369874 },
            map: map,
            title: mapUea.title,
            icon: {
                url: mapUea.icon,
                scaledSize: new google.maps.Size(50, 50),
            },
        });

        nua = new google.maps.Marker({
            position: { lat: 52.6322179, lng: 1.2908152 },
            map: map,
            title: mapNua.title,
            icon: {
                url: mapNua.icon,
                scaledSize: new google.maps.Size(50, 50),
            },
        });

        google.maps.event.addListener(infowindow, "domready", function () {
            document.getElementById("infowindow").parentNode.style.overflow =
                "hidden";
            document.getElementById(
                "infowindow"
            ).parentNode.parentNode.style.overflow = "hidden";

            setTimeout("infowindow.open(map,marker);", 1000);
        });
    }
}
