import {
    defaultOptions,
    prevArrowCustom,
    nextArrowCustom,
} from "../utilities/slider";

const modalSlider = jQuery('[data-slider="modal-slider"]');

const sliderOptions = {
    ...defaultOptions,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    prevArrow: prevArrowCustom(modalSlider),
    nextArrow: nextArrowCustom(modalSlider),
};

jQuery(".open-modal").on("click", function () {
    var id = jQuery(this).attr("modal-id");
    jQuery(".modal#" + id).css("display", "flex");
    modalSlider.slick(sliderOptions);
});

jQuery(".modal-close").on("click", function () {
    var id = jQuery(this).closest(".modal").attr("id");
    jQuery(".modal#" + id).hide();
    modalSlider.slick("unslick");
});
